// import logo from './logo.svg';
import React, { useEffect } from 'react';
import './App.css';
import './css/styles.css'
import logo from './img/logo-turistik.jpg'
import copy from 'clipboard-copy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {

  useEffect(() => {
    document.title = 'Turisti-K Travels';
  }, []);

  const copyToClipboard = () => {
    const url = window.location.href;
    copy(url);
    toast.info('Enlace copiado...', { autoClose: 1500 });
    // alert('¡Enlace copiado al portapapeles!');
  };

  return (
    <div className="wrapper">
  <div className="profile-card js-profile-card">
    <div className="profile-card__img">
      <img className='App-logo'
        src={logo}
        alt="profile card"
      />
    </div>
    <div className="profile-card__cnt js-profile-cnt">
      <div className="profile-card__name">Turisti-K Travels</div>
        <div className="profile-card__txt">
          Agencia de Viajes
        </div>
        <div className="profile-card-loc">
          <span className="profile-card-loc__icon">
            <i class="fa-solid fa-location-dot"></i>
          </span>
          <span className="profile-card-loc__txt">Tuxtla Gutiérrez, Chiapas, México</span>
        </div>
        <div className="profile-card-loc">
          <span className="profile-card__txt mt-2">
          Ofrecemos una amplia gama de servicios para que disfrutes de una experiencia inolvidable. 
          Con nosotros puedes reservar hospedaje en los mejores hoteles, comprar vuelos, 
          contratar traslados seguros, rentar autos y más. 
          Nuestro objetivo es brindarte un servicio personalizado y de calidad, para que tu próxima aventura sea inigualable. 
          ¡Descubre el mundo con Turisti-K Travels!
          </span>
        </div>
        <div className="profile-card-inf">
          <div className="profile-card-inf__item">
            <div className="profile-card-inf__title"><i class="fa-solid fa-hotel"></i></div>
            <div className="profile-card-inf__txt">Hospedaje</div>
          </div>
          <div className="profile-card-inf__item">
            <div className="profile-card-inf__title"><i class="fa-solid fa-car"></i></div>
            <div className="profile-card-inf__txt">Renta de Autos</div>
          </div>
          <div className="profile-card-inf__item">
            <div className="profile-card-inf__title"><i class="fa-solid fa-plane"></i></div>
            <div className="profile-card-inf__txt">Paq. Vuelos</div>
          </div>
          <div className="profile-card-inf__item">
            <div className="profile-card-inf__title"><i class="fa-solid fa-earth-americas"></i></div>
            <div className="profile-card-inf__txt">Circuitos</div>
          </div>
          <div className="profile-card-inf__item">
            <div className="profile-card-inf__title"><i class="fa-solid fa-route"></i></div>
            <div className="profile-card-inf__txt">Tours</div>
          </div>
          <div className="profile-card-inf__item">
            <div className="profile-card-inf__title"><i class="fa-solid fa-face-surprise"></i></div>
            <div className="profile-card-inf__txt">Y más...</div>
          </div>
        </div>
        <div className="profile-card__txt mt-1">
          ¡Contáctanos en nuestras redes sosciales, con gusto te atenderemos!
        </div>
        <div className="profile-card-social">
          <a href="https://www.facebook.com/profile.php?id=61551814531109&mibextid=ZbWKwL" className="profile-card-social__item facebook" target="_blank">
            <span className="icon-font">
            <i class="fa-brands fa-facebook"></i>
            </span>
          </a>
          <a href="https://www.instagram.com/turisti_k_travels?igsh=OGcyenNpeWVuNWtp" className="profile-card-social__item instagram" target="_blank">
            <span className="icon-font">
              <i class="fa-brands fa-instagram"></i>
            </span>
          </a>
          <a href="https://api.whatsapp.com/send?phone=529616678493" className="profile-card-social__item whatsapp" target="_blank">
            <span className="icon-font">
              <i class="fa-brands fa-whatsapp"></i>
            </span>
          </a>
          <a href="https://www.tiktok.com/@turisti.k.travels?_t=8kFcYI3l48D&_r=1" className="profile-card-social__item tiktok" target="_blank">
            <span className="icon-font">
              <i class="fa-brands fa-tiktok"></i>
            </span>
          </a>
          <a href="tel:+529616678493" className="profile-card-social__item whatsapp" >
            <span className="icon-font">
              <i class="fa-solid fa-phone"></i>
            </span>
          </a>
          <a href="mailto:viajesturistik2023@gmail.com" className="profile-card-social__item email" >
            <span className="icon-font">
              <i class="fa-solid fa-envelope"></i>
            </span>
          </a>
          <button type="button" onClick={copyToClipboard} className="profile-card-social__item share">
            <span className="icon-font">
              <i class="fa-solid fa-share-nodes"></i>
            </span>
          </button>
          <ToastContainer />
        </div>
        <div className="profile-card-inf">
        <div className="profile-card__txt mt-1">
          Encuéntranos en Andador París No. 110, C.P. 29087, Col. Potrero Mirador, Tuxtla Gutiérrez, Chiapas, México
        </div>
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d238.8032505645192!2d-93.11965566440139!3d16.734234375555726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2smx!4v1712275177361!5m2!1ses!2smx"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        {/* <div className="profile-card-ctr">
          <button className="profile-card__button button--blue js-message-btn">
            Message
          </button>
          <button className="profile-card__button button--orange">Follow</button>
        </div> */}
    </div>
    <div className="profile-card-message js-message">
      <form className="profile-card-form">
        <div className="profile-card-form__container">
          <textarea placeholder="Say something..." defaultValue={""} />
        </div>
        <div className="profile-card-form__bottom">
          <button className="profile-card__button button--blue js-message-close">
            Send
          </button>
          <button className="profile-card__button button--gray js-message-close">
            Cancel
          </button>
        </div>
      </form>
      <div className="profile-card__overlay js-message-close" />
    </div>
  </div>
</div>
  );
}

export default App;
